import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CardDeck from 'react-bootstrap/CardDeck'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import cardImg from '../images/download.jpg'
import { useMediaQuery } from 'react-responsive'
import Dropdown from 'react-bootstrap/Dropdown'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {Redirect} from "@reach/router"


const FrozenPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    console.log(isTabletOrMobile);
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   return(
       
   <Layout>
               {//<Redirect noThrow from="/404"to={"/comingsoon"}/>
}

        <SEO title="Frozen Goods" />
        <br/>
        <div style={{
      display: 'inline-block',
      height: '60 px',
      width: '100%',
      background: 'yellow',
    }}>
      <h2 style={{
        fontFamily: '',
        textAlign: 'center',
        color: 'red'
      }}>Frozen Goods</h2></div>
      <br/> 
      <br/>
          <Row className="text-center">
          <ToggleButtonGroup type="radio" name="options" defaultValue={1} className="mx-auto">
            <ToggleButton variant="secondary" value={1}>1</ToggleButton>
            <ToggleButton variant="secondary" value={2}>2</ToggleButton>
            <ToggleButton variant="secondary" value={3}>3</ToggleButton>
            <ToggleButton variant="secondary" value={4}>4</ToggleButton>
  </ToggleButtonGroup>      
      </Row>
      <br/>
      <CardDeck className='mx-auto'>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}> 
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}>
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}>
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
      </CardDeck>
      <br/>
      <CardDeck className='mx-auto'>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}> 
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}>
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}>
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
      </CardDeck>
      <br/>
      <CardDeck className='mx-auto'>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}> 
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}>
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
    <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}>
        <Card.Img variant="top" src={cardImg} height='140' width='180'/>
        <Card.Body>
            <Card.Title>Steak</Card.Title>
            <Card.Subtitle>Meat and Poultry</Card.Subtitle>
            <Card.Text>
                Yes I really love steak.
            </Card.Text>
            <Button variant="primary" onClick={handleShow}>Read more</Button>
        </Card.Body>
    </Card>
      </CardDeck>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Steak</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <img src={cardImg}/>
            <strong>Meat and Poultry</strong>
            <br/>
            Yes I really love steak. The flavor that it provides is unparalleled and it is a great source of protien. Not to mention it is a very comfortable food. 
            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
   </Layout>

      );
};

export default FrozenPage
